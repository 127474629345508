
import { debounce } from "debounce";
export default {
  props:['element'],
  name: "searchSuggestions",
  data() {
    return {
      keys: {},
      q: "",
      focus: false,
      lock: false,
      suggestions: [],
    };
  },
  created() {
    let self = this;
    this.$nuxt.$on("focusSearch", () => {
      setTimeout(() => {
        try {
          self.$refs.search.focus();
        } catch (error) {
          console.error('no focus');          
        }
      }, 50);
    });
  },
  methods: {
    close() {
      this.focus = false;
    },
    blur() {
      try {
        document.activeElement.blur();
      } catch (error) {}
    },
    arrowNav(e) {
      e.preventDefault();
      e.stopPropagation();
      if (process.server) {
        return;
      }
      try {
        if (e.keyCode == 38) {
          if (!document.activeElement.previousSibling) return;
          document.activeElement.previousSibling.focus();
          this.q =
            document.activeElement.querySelector("span.actual").innerText;
        } else if (e.keyCode == 40) {
          if (!document.activeElement.nextSibling) return;
          document.activeElement.nextSibling.focus();
          this.q =
            document.activeElement.querySelector("span.actual").innerText;
        }
      } catch (error) {
        console.warn(error);
      }
    },
    async suggest(e) {
      let code = e.which;
      
      if (code == 13) {
        this.$nuxt.$emit('informant:search', this.q)
        this.$router.push(`/${this.$store.getters.store.eshop.catalogRoute}/?q=${this.q}`);
        this.$nuxt.$emit('closeMenu');
        try {
          this.blur();
        } catch (error) {
        }
        return;
      }
      if (code == 40) {
        try {
          if (!document.querySelector(".suggestion-links > a")) return;
          document.querySelector(".suggestion-links > a").focus();
          try {
            this.q =
              document.activeElement.querySelector("span.actual").innerText;
          } catch (error) {
            console.warn(error);
          }
        } catch (error) {}
        return;
      }
      if ((code < 48 || code > 111) && !this.$store.getters.isMobile) {
        return;
      }
      let self = this;
      if (self.lock || (self.q || "").length < 3) {
        return;
      }
      self.lock = true;
      debounce(async function () {
        let fields = "";
        let order = {
          business_activity: 0,
          family: 1,
          subfamily: 2,
          category: 3,
          persons: 5,
          description: 4,
        };
        if (self.$store.getters.store.eshop.filtersSuggest) {
          fields = "&fields=" + self.$store.getters.store.eshop.filtersSuggest;
          order = self.$store.getters.store.eshop.filtersSuggest
            .split(",")
            .reduce((acc, fs, index) => {
              acc[fs] = index;
              return acc;
            }, {});
        }
        try {
          let temp = await self.$axios.$get(
            `/items/sv/autocomplete?store_id=${self.$store.getters.store._id}&q=${self.q}${fields}`
          );
          if (!Array.isArray(temp)) {
            temp = false;
          }
          if (temp) {
            self.suggestions = (
              (temp || []).map((s) => {
                s.order = order[s.tag ? "tag." + s.tag : s.key];
                if (isNaN(s.order)) {
                  s.order = 10;
                }
                return s;
              }) || []
            ).sort((a, b) => a.order - b.order);
          }
          self.lock = false;
        } catch (e) {
          console.error(e);
          self.lock = false;
        }
      }, 600)();
    },
  },
};
